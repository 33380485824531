import React from 'react'
import FAQsection from './FAQsection'
import { Helmet } from 'react-helmet';

function FAQs() {
  return (
    <>
      <Helmet>
        <title>
          ZATCA User Guide | Answers to Your E-Invoicing & ERP Integration Questions
        </title>
        <meta
          name="description"
          content="Find friendly answers and guide to your questions about ZATCA compliant e-invoicing and ERP/POS integration."
        />
        <meta
          name="keywords"
          content="Contact ZATCA API, ZATCA compliance support, e-invoicing inquiries, digital signing questions, customer support"
        />
        <meta property="og:title" content="Contact Us – ZATCA API" />
        <meta
          property="og:description"
          content="Reach out to ZATCA API for all your compliance needs and inquiries about our services."
        />
        <meta property="og:url" content="https://yourwebsite.com/contact-us" />
        <meta property="og:type" content="website" />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <div className="text-center pt-24 pb-12 bg-[#071C40]">
        <h1 className="text-4xl   text-gray-200 leading-tight">
          Frequently
          asked
          <span className="text-blue-600 px-2">Questions</span>
        </h1>
        <p className="mt-4 text-lg text-gray-300 max-w-2xl mx-auto">
          ZATCA API Services leads the way in e-invoicing and ERP solutions,
          tailored for Saudi businesses. Our solutions blend simplicity
          with quality.
        </p>
      </div>
      <FAQsection></FAQsection>
    </>
  )
}

export default FAQs