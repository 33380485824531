import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/data';
import { useUser } from '@clerk/clerk-react';
import UserStats from './UserStats';

function Dashboard() {
    const { user } = useUser();
    const { authURL } = useAuth();
    const [loading, setLoading] = useState(true);

    const sendClientData = async () => {
        const clientData = {
            fullName: user.fullName,
            email: user.primaryEmailAddress.emailAddress,
        };

        try {
            const response = await fetch(`${authURL}/post-user-stats`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(clientData),
            });

            if (response.status === 412) {
                console.error('Already Exists');
            } else if (response.status === 201) {
                console.log('OK');
            } else {
                console.log("Internal Server Error");
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false); // Set loading to false after fetch completes
        }
    };

    useEffect(() => {
        sendClientData();
    }, []);

    return (
        <>
            <div className='w-full max-w-5xl mx-auto space-y-4 h-[99vh]'>
                {loading ? (
                    <div className="max-w-7xl mx-auto flex items-center justify-center h-screen text-6xl">
                        <l-zoomies
                            size="80"
                            stroke="5"
                            bg-opacity="0.1"
                            speed="1.4"
                            color="black"
                        ></l-zoomies>
                    </div>
                ) : (
                    <UserStats></UserStats>
                )}
            </div>
        </>
    );
}

export default Dashboard;
