import React from 'react';
import { Link } from 'react-router-dom';

const TaxPayerCSR = () => {
    return (
        <div className='flex flex-col space-y-8'>

            <div>
                <h1 className='text-5xl font-light mb-2'>Generate Tax Payer CSR</h1>
                <p className='text-md text-gray-600'>Generate a <code className='text-blue-700'>PrivateKey.pem</code> ,<code className='text-blue-700'> config.cnf</code> and <code className='text-blue-700'>taxpayer.csr</code> file</p>
            </div>


            <div className="w-full">

                <div className="mb-4">
                    <h3 className="text-xl font-semibold mb-2">Endpoint</h3>
                    <p className='text-sm text-gray-600 mb-2'>Upload a Base64 of an XML then it will return the hash of the processed invoice.</p>
                    <pre className="bg-neutral-800 p-4 rounded text-gray-200 whitespace-pre-wrap">
                        <code className="text-blue-400">
                            <span className="text-green-400">https://</span>
                            <span className="text-yellow-400">zatca.</span>
                            <span className="text-yellow-300">bzsconnect</span>
                            <span className="text-yellow-400">.com</span>
                            <span className="text-green-400">/api</span>
                            <span className="text-blue-400">/taxpayer</span>
                        </code>
                    </pre>
                </div>

                <div className="mb-4">
                    <h3 className="text-xl font-semibold mb-1">Options</h3>
                    <p className='text-sm text-gray-600 mb-2'>You can find Authorization Credentials from here: <Link to="/dashboard/authorization" className='pl-2 text-blue-500 underline'>Click here</Link></p>
                    <pre className="bg-neutral-800 p-4 rounded text-gray-200 whitespace-pre-wrap">
                        <code>
                            <span className="text-green-400">const</span> <span className="text-blue-400">options</span> = {'{'}
                            <br />
                            {'\u00A0\u00A0\u00A0\u00A0'}<span className="text-yellow-400">method</span>: <span className="text-red-400">'POST'</span>,
                            <br />
                            {'\u00A0\u00A0\u00A0\u00A0'}<span className="text-yellow-400">headers</span>: {'{'}
                            <br />
                            {'\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0'}<span className="text-yellow-400">'Content-Type'</span>: <span className="text-red-400">'application/json'</span>,
                            <br />
                            {'\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0'}<span className="text-yellow-400">'Authorization'</span>: <span className="text-red-400">'Basic '</span> + <span className="text-blue-400"></span>(<span className="text-red-400">'username:password'</span>),
                            <br />
                            {'\u00A0\u00A0\u00A0\u00A0'}{'}'}
                            <br />
                            {'}'}
                        </code>
                    </pre>

                </div>

                <div className="mb-4">
                    <h3 className="text-xl font-semibold mb-2">Parameters <span className="text-red-500 font-normal text-sm">required</span></h3>
                    <p className='text-sm text-gray-600 mb-2'>Upload a Base64 of an XML then it will return the hash of the processed invoice.</p>
                    <pre className="bg-neutral-800 p-4 rounded text-gray-200 whitespace-pre-wrap">
                        <code>
                            <span className="text-red-400">{'{ '}</span>
                            <span className="text-yellow-400">file</span>
                            <span className="text-red-400">{' }'}</span>
                        </code>
                    </pre>
                </div>
                <div className="mb-4">
                    <h3 className="text-xl font-semibold mb-2">Response</h3>
                </div>
                <div className="space-y-4 p-4 bg-neutral-800 rounded text-gray-200">
                    {/* Success message */}
                    <p className="text-green-400 text-sm">
                        privateKey.pem and taxpayer.csr generated successfully.
                    </p>

                    {/* CSR content */}
                    <pre className="text-sm overflow-x-auto">
                        <code className="text-yellow-400">
                            -----BEGIN CERTIFICATE REQUEST-----
                        </code>
                        <br />
                        <code className="text-green-400">
                            MIICLzCCAdUCAQAwTjELMAkGA1UEBhMCU0ExEzARBgNVBAsMCjMwMjAwODAxMTcx
                            <br />
                            EjAQBgNVBAoMCW9vbWNvLmNvbTEWMBQGA1UEAwwNMjg1LW9vbWNvLmNvbTBZMBMG
                            <br />
                            ByqGSM49AgEGCCqGSM49AwEHA0IABNfhA6KD0SfAdRfvPvj19+hZik+BMe3KHYE0
                            <br />
                            Ihx1rMSmeJ5Hgm4nG1ExIdvsZJ1P1HM25LjWMx/bjgcA3SzPLG+gggEjMIIBHwYJ
                            <br />
                            KoZIhvcNAQkOMYIBEDCCAQwwJAYJKwYBBAGCNxQCBBcTFVBSRVpBVENBLUNvZGUt
                            <br />
                            U2lnbmluZzCB4wYDVR0RBIHbMIHYpIHVMIHSMTwwOgYDVQQEDDMxLU9PTUNPfDIt
                            <br />
                            VFYxfDMtZWQyMmYxZDgtZTZhMi0xMTE4LTliNTgtZDlhOGYxMWU0NjMxHzAdBgoJ
                            <br />
                            kiaJk/IsZAEBDA8zMDIwMDgwMTE3MDAwMDMxDTALBgNVBAwMBDAxMDAxNTAzBgNV
                            <br />
                            BBoMLEFsIE11aGFtbWFkaXlhaCwgRGFtbWFtIDMyNDMzLCBTYXVkaSBBcmFiaWEu
                            <br />
                            MSswKQYDVQQPDCJPTUFOIE9JTCBNQVJLRVRJTkcgQ09NUEFOWSAoT09NQ08pMAoG
                            <br />
                            CCqGSM49BAMCA0gAMEUCIQDYujEy+2T/NPWFOyy+dU9H8vmeEMtt9LwkdpDHv7qu
                            <br />
                            nwIgW4MH4jPCObq3/KZQ6NmA5wKtgBxKBr+nr4W6H8oPEbA=
                        </code>
                        <br />
                        <code className="text-yellow-400">
                            -----END CERTIFICATE REQUEST-----
                        </code>
                    </pre>

                    <pre className="text-sm overflow-x-auto">
                        <code className="text-yellow-400">
                            -----BEGIN EC PRIVATE KEY-----
                        </code>
                        <br />
                        <code className="text-green-400">
                            MHcCAQEEIGW0/9P5cHuje8+NFZNiLWUK973QqvRZB48f0ax8xlu2oAoGCCqGSM49
                            <br />
                            AwEHoUQDQgAEbBgbZIhHqFfH+wNHZ/XpogsXOnhxrIPnf8Zq5YODALl22PJp+hg0
                            <br />
                            XclONzAunPXbBBv+s+5ha0214N/IOyT05g==
                        </code>
                        <br />
                        <code className="text-yellow-400">
                            -----END EC PRIVATE KEY-----
                        </code>
                    </pre>
                </div>
            </div>
        </div>
    );
};

export default TaxPayerCSR;
