import React, { useState, useEffect } from 'react';
import { useUser } from '@clerk/clerk-react';
import { useAuth } from '../../context/data';
import SubscriptionTime from './SubscriptionTime';
import { Link } from 'react-router-dom';

const UserStats = () => {
    const { authURL } = useAuth();
    const { user, isSignedIn } = useUser();
    const [status, setStatus] = useState(null);
    const [packageData, setPackageData] = useState(null);
    const [purchasedOn, setPurchasedOn] = useState(null);
    const [purchasedExpiry, setPurchasedExpiry] = useState(null);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const monthNames = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];
        const month = monthNames[date.getMonth()];
        const year = date.getFullYear();
        return `${day} ${month}, ${year}`;
    };

    const getUserStats = async () => {
        if (isSignedIn && user) {
            try {
                const response = await fetch(`${authURL}/get-user-stats?email=${user.primaryEmailAddress.emailAddress}`);
                if (response.status === 404) {
                    console.error("User stats not found");
                    return;
                }
                const data = await response.json();
                console.log(data);

                setStatus(data.status);
                setPackageData(data.package);
                setPurchasedOn(formatDate(data.purchasedOn));
                setPurchasedExpiry(formatDate(data.purchasedExpiry));
            } catch (error) {
                console.error("Error fetching user stats:", error);
            }
        }
    };

    useEffect(() => {
        getUserStats();
    }, [isSignedIn, user]);

    if (!status) return null;

    // Conditional rendering based on status
    if (status !== 'premium') {
        return <SubscriptionTime />;
    }

    return (
        <div className="min-h-screen">
            <div className="max-w-7xl mx-auto">
                <div className="flex justify-between items-center mb-8">
                    <div>
                        <h1 className="text-3xl text-gray-800 font-semibold">Account Overview</h1>
                        <p className="text-gray-500 mt-2">Manage your subscription and billing details</p>
                    </div>
                    <div className='space-x-4 flex'>
                        <div className="bg-green-50 border border-green-200 px-4 py-2 rounded-full">
                            <span className="text-green-600 text-sm ">● Active Account</span>
                        </div>
                        <Link to='checkout-status' className="bg-gradient-to-tl from-blue-700 to-blue-800 px-4 py-2 rounded-lg hover:rounded-full transition-all duration-100">
                            <span className="text-gray-100 text-sm ">Check Payment Status</span>
                        </Link>
                    </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
                    {/* Current Plan Card */}
                    <div className="bg-white rounded p-6 border border-slate-100">
                        <div className="flex items-center justify-between mb-4">
                            <div className="bg-blue-50 p-3 rounded-lg">
                                <span className="text-2xl">📦</span>
                            </div>
                            <span className="text-sm text-gray-400">Subscription Plan</span>
                        </div>
                        <h2 className="text-2xl font-semibold text-gray-800 mb-1">{packageData}</h2>
                        <p className="text-gray-500 text-sm">Current active plan</p>
                    </div>

                    {/* Next Billing Date Card */}
                    <div className="bg-white rounded p-6 border border-slate-100">
                        <div className="flex items-center justify-between mb-4">
                            <div className="bg-red-50 p-3 rounded-lg">
                                <span className="text-2xl">⏰</span>
                            </div>
                            <span className="text-sm text-gray-400">Next Payment</span>
                        </div>
                        <h2 className="text-2xl font-semibold text-red-600 mb-1">{purchasedExpiry}</h2>
                        <p className="text-gray-500 text-sm">Next billing cycle</p>
                    </div>

                    {/* Subscription Date Card */}
                    <div className="bg-white rounded p-6 border border-slate-100">
                        <div className="flex items-center justify-between mb-4">
                            <div className="bg-purple-50 p-3 rounded-lg">
                                <span className="text-2xl">📅</span>
                            </div>
                            <span className="text-sm text-gray-400">Member Since</span>
                        </div>
                        <h2 className="text-2xl font-semibold text-gray-800 mb-1">{purchasedOn}</h2>
                        <p className="text-gray-500 text-sm">Initial subscription date</p>
                    </div>
                </div>

                {/* Account Details Section */}
                <div className="bg-white rounded p-6 border border-slate-100">
                    <h3 className="text-xl text-gray-800 font-semibold mb-6">Account Details</h3>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                        <div>
                            <div className="flex items-center gap-3 mb-2">
                                <div className="h-2 w-2 rounded-full bg-green-500"></div>
                                <p className="text-sm text-gray-500">Account Status</p>
                            </div>
                            <p className="text-lg text-gray-800">Active</p>
                        </div>
                        <div>
                            <div className="flex items-center gap-3 mb-2">
                                <div className="h-2 w-2 rounded-full bg-blue-500"></div>
                                <p className="text-sm text-gray-500">Email Address</p>
                            </div>
                            <p className="text-lg text-gray-800">
                                {user?.primaryEmailAddress?.emailAddress}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserStats;
