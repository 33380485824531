import React from "react";
import { useLocation } from "react-router-dom";

function BlogDetails() {
    const location = useLocation();
    const blog = location.state;

    if (!blog) {
        return <div className="text-center py-24">Blog not found!</div>;
    }

    return (
        <div className="py-24">
            <div className="max-w-4xl mx-auto text-center">
                <h1 className="text-4xl font-bold">{blog.title}</h1>
                <p className="text-gray-500 mt-4">{blog.date}</p>
                <img
                    src={blog.image}
                    alt={blog.title}
                    className="w-full rounded-xl mt-8 mb-8"
                />
                <p className="text-lg text-gray-700">{blog.description}</p>
            </div>
        </div>
    );
}

export default BlogDetails;
