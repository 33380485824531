import React from 'react'

function Stats() {
    return (
        <div>
            <section className="py-12 bg-[#071C40]">
                <div className="max-w-7xl mx-auto px-4">
                    <h1 className="font-heading mb-4 logo text-4xl font-bold text-gray-200">
                        ZATCA in Numbers
                    </h1>
                    <div className="flex flex-wrap -mx-4">
                        <div className="w-full lg:w-1/3 p-4">
                            <div className="bg-white rounded py-6 px-4 flex flex-col items-center justify-center space-y-3">
                                <i class="fa-duotone fa-light fa-user-headset text-4xl text-[#071C40]"></i>
                                <h2 className="text-blue-700 text-4xl font-bold font-heading text-center">00h : 10m : 00s</h2>
                                <p className="text-gray-700 font-medium text-center">Average Call Response Time</p>
                            </div>
                        </div>
                        <div className="w-full lg:w-1/3 p-4">
                            <div className="bg-white rounded py-6 px-4 flex flex-col items-center justify-center space-y-3">
                                <i class="fa-duotone fa-thin fa-file-invoice text-4xl text-[#071C40]"></i>
                                <h2 className="text-blue-700 text-4xl font-bold font-heading text-center">+4 Million</h2>
                                <p className="text-gray-700 font-medium text-center">Invoices Generated, Reported & Cleared</p>
                            </div>
                        </div>
                        <div className="w-full lg:w-1/3 p-4">
                            <div className="bg-white rounded py-6 px-4 flex flex-col items-center justify-center space-y-3">
                                <i class="fa-duotone fa-thin fa-lightbulb-gear text-4xl text-[#071C40]"></i>
                                <h2 className="text-blue-700 text-4xl font-bold font-heading text-center">+750</h2>
                                <p className="text-gray-700 font-medium text-center">Qualified Solution Provider</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Stats
