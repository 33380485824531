import React from 'react';
import InvoiceHashComponent from './Form';

const InvoiceHashing = () => {

    return (
        <>
            <div className='mb-8'>
                <h1 className='text-5xl font-light mb-2'>Invoice Signing</h1>
                <p className='text-md text-gray-600'>Upload a base64 of an XML and get it signed version.</p>
            </div>
            <InvoiceHashComponent></InvoiceHashComponent>
        </>
    );
};

export default InvoiceHashing;