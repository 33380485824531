import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import ServicesTabs from './ServicesTabs';
import HeroSection from './HeroSection';
import Stats from './Stats';
import Services from './Services';
import MoreOverSection from './MoreOverSection';
import CTA from './CTA';

const Homepage = () => {
    useEffect(() => {
        const url = 'https://wati-integration-prod-service.clare.ai/v2/watiWidget.js?56156';
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = url;

        script.onload = () => {
            if (window.CreateWhatsappChatWidget) {
                const options = {
                    enabled: true,
                    chatButtonSetting: {
                        backgroundColor: '#00e785',
                        ctaText: 'Chat with us',
                        borderRadius: '25',
                        marginLeft: '20',
                        marginRight: '0',
                        marginBottom: '20',
                        ctaIconWATI: true,
                        position: 'left',
                    },
                    brandSetting: {
                        brandName: 'ZATCA',
                        brandSubTitle: 'undefined',
                        brandImg: 'https://www.wati.io/wp-content/uploads/2023/04/Wati-logo.svg',
                        welcomeText: 'Hi there!\nHow can I help you?',
                        messageText: 'Hello, %0A I have a question about {{ page_link }}',
                        backgroundColor: '#00e785',
                        ctaText: 'Chat with us',
                        borderRadius: '25',
                        autoShow: false,
                        phoneNumber: '+923217678097',
                    },
                };
                window.CreateWhatsappChatWidget(options);
            } else {
                console.error('CreateWhatsappChatWidget function is not available.');
            }
        };

        const firstScript = document.getElementsByTagName('script')[0];
        firstScript.parentNode.insertBefore(script, firstScript);

        return () => {
            script.remove();
        };
    }, []);

    return (
        <div className="px-0 md:mx-0">
            <Helmet>
                <title>Top Saudi Arabia E-Invoicing Solution | Top Zatca E-Invoicing - KSA</title>
                <meta
                    name="description"
                    content="Unlock the future of business in Saudi Arabia with our ZATCA-approved e-invoicing platform, optimized for seamless VAT compliance."
                />
                <meta
                    name="keywords"
                    content="ZATCA API, e-invoicing, compliance services, Saudi Arabia, invoice hashing, digital signing"
                />
                <meta property="og:title" content="ZATCA API – E-Invoicing & Compliance Services for Saudi Arabia" />
                <meta
                    property="og:description"
                    content="API services for ZATCA compliance including e-invoicing, hashing, signing invoices, and more for Saudi businesses."
                />
                <meta property="og:url" content="https://yourwebsite.com" />
                <meta property="og:type" content="website" />
            </Helmet>

            <HeroSection />
            <ServicesTabs />
            <Stats />
            <Services />
            <MoreOverSection />
            <CTA />
        </div>
    );
};

export default Homepage;
