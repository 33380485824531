import React from "react";
import PricingCard from "./PricingCard";
import { Helmet } from 'react-helmet';

function Pricing() {
  <Helmet>
    <title>
      ZATCA Pricing | Affordable E-Invoicing & ERP Integration Solutions
    </title>
    <meta
      name="description"
      content="Choose the right plan for your business needs and ensure seamless tax compliance with ZATCA."
    />
    <meta
      name="keywords"
      content="Contact ZATCA API, ZATCA compliance support, e-invoicing inquiries, digital signing questions, customer support"
    />
    <meta property="og:title" content="Contact Us – ZATCA API" />
    <meta
      property="og:description"
      content="Reach out to ZATCA API for all your compliance needs and inquiries about our services."
    />
    <meta property="og:url" content="https://yourwebsite.com/contact-us" />
    <meta property="og:type" content="website" />
    <meta name="robots" content="index, follow" />
  </Helmet>
  return (
    <>
      <div className="text-center pt-24 pb-12 bg-[#071C40]">
        <h1 className="text-4xl   text-gray-200 leading-tight">
          Choose a
          <span className="text-blue-600 px-2">Plan</span>
          for your work limit
        </h1>
        <p className="mt-4 text-lg text-gray-300 max-w-2xl mx-auto">
          ZATCA API Services leads the way in e-invoicing and ERP solutions, tailored for Saudi businesses. Our solutions blend simplicity with quality.
        </p>
      </div>
      <div class="py-10  bg-blue-50">
        <PricingCard />
      </div>
    </>
  );
}

export default Pricing;
