import React from "react";
import { useNavigate } from "react-router-dom";

function Blogs() {
    const navigate = useNavigate();

    const handleReadMore = (blog) => {
        navigate(`/blog-details`, { state: blog });
    };

    return (
        <>
            <div className="text-center pt-24 pb-12 bg-[#071C40]">
                <h1 className="text-4xl text-gray-200 leading-tight">
                    Read our latest
                    <span className="text-blue-600 px-2">Blogs</span>
                </h1>
                <p className="mt-4 text-lg text-gray-300 max-w-2xl mx-auto">
                    ZATCA APIs Services leads the way in e-invoicing and ERP solutions,
                    tailored for Saudi businesses. Our solutions blend simplicity with quality.
                </p>
            </div>

            <section className="py-24">
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                    <div className="flex justify-center gap-y-8 lg:gap-y-0 flex-wrap md:flex-wrap lg:flex-nowrap lg:flex-row lg:justify-between lg:gap-x-8">
                        {/* Blog 1 */}
                        <div className="group w-full max-lg:max-w-xl lg:w-1/3 border border-gray-300 rounded-2xl">
                            <div className="flex items-center">
                                <img
                                    src="https://pagedone.io/asset/uploads/1696244317.png"
                                    alt="Blog 1"
                                    className="rounded-t-2xl w-full object-cover"
                                />
                            </div>
                            <div className="p-4 lg:p-6 transition-all duration-300 rounded-b-2xl group-hover:bg-gray-50">
                                <span className="text-indigo-600 font-medium mb-3 block">Jan 01, 2023</span>
                                <h4 className="text-xl text-gray-900 font-medium leading-8 mb-5">
                                    Clever ways to invest in product to organize your portfolio
                                </h4>
                                <p className="text-gray-500 leading-6 mb-10">
                                    Discover smart investment strategies to streamline and organize your portfolio..
                                </p>
                                <button
                                    onClick={() =>
                                        handleReadMore({
                                            title: "Clever ways to invest in product to organize your portfolio",
                                            date: "Jan 01, 2023",
                                            description: "Discover smart investment strategies to streamline and organize your portfolio..",
                                            image: "https://pagedone.io/asset/uploads/1696244317.png",
                                        })
                                    }
                                    className="cursor-pointer text-lg text-indigo-600 font-semibold"
                                >
                                    Read more..
                                </button>
                            </div>
                        </div>

                        {/* Blog 2 */}
                        <div className="group w-full max-lg:max-w-xl lg:w-1/3 border border-gray-300 rounded-2xl">
                            <div className="flex items-center">
                                <img
                                    src="https://pagedone.io/asset/uploads/1696244340.png"
                                    alt="Blog 2"
                                    className="rounded-t-2xl w-full object-cover"
                                />
                            </div>
                            <div className="p-4 lg:p-6 transition-all duration-300 rounded-b-2xl group-hover:bg-gray-50">
                                <span className="text-indigo-600 font-medium mb-3 block">Feb 01, 2023</span>
                                <h4 className="text-xl text-gray-900 font-medium leading-8 mb-5">
                                    How to grow your profit through systematic investment with us
                                </h4>
                                <p className="text-gray-500 leading-6 mb-10">
                                    Unlock the power of systematic investment with us and watch your profits soar..
                                </p>
                                <button
                                    onClick={() =>
                                        handleReadMore({
                                            title: "How to grow your profit through systematic investment with us",
                                            date: "Feb 01, 2023",
                                            description: "Unlock the power of systematic investment with us and watch your profits soar..",
                                            image: "https://pagedone.io/asset/uploads/1696244340.png",
                                        })
                                    }
                                    className="cursor-pointer text-lg text-indigo-600 font-semibold"
                                >
                                    Read more..
                                </button>
                            </div>
                        </div>

                        {/* Blog 3 */}
                        <div className="group w-full max-lg:max-w-xl lg:w-1/3 border border-gray-300 rounded-2xl">
                            <div className="flex items-center">
                                <img
                                    src="https://pagedone.io/asset/uploads/1696244356.png"
                                    alt="Blog 3"
                                    className="rounded-t-2xl w-full object-cover"
                                />
                            </div>
                            <div className="p-4 lg:p-6 transition-all duration-300 rounded-b-2xl group-hover:bg-gray-50">
                                <span className="text-indigo-600 font-medium mb-3 block">Mar 01, 2023</span>
                                <h4 className="text-xl text-gray-900 font-medium leading-8 mb-5">
                                    How to analyze every holdings of your portfolio
                                </h4>
                                <p className="text-gray-500 leading-6 mb-10">
                                    Our comprehensive guide will equip you with the tools and insights needed..
                                </p>
                                <button
                                    onClick={() =>
                                        handleReadMore({
                                            title: "How to analyze every holdings of your portfolio",
                                            date: "Mar 01, 2023",
                                            description: "Our comprehensive guide will equip you with the tools and insights needed..",
                                            image: "https://pagedone.io/asset/uploads/1696244356.png",
                                        })
                                    }
                                    className="cursor-pointer text-lg text-indigo-600 font-semibold"
                                >
                                    Read more..
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Blogs;
