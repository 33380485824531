import React from 'react';
import { Link } from 'react-router-dom';

const Compliance = () => {
    return (
        <div className='flex flex-col space-y-8'>

            <div>
                <h1 className='text-5xl font-light mb-2'>Compliance</h1>
                <p className='text-md text-gray-600'>After onboarding your device to get started with Invoice Clearance and Production processes.</p>
            </div>


            <div className="w-full">

                <div className="mb-4">
                    <h3 className="text-xl font-semibold mb-2">Endpoint</h3>
                    <p className='text-sm text-gray-600 mb-2'>Upload a Base64 of an XML then it will return the hash of the processed invoice.</p>
                    <pre className="bg-neutral-800 p-4 rounded text-gray-200 whitespace-pre-wrap">
                        <code className="text-blue-400">
                            <span className="text-green-400">https://</span>
                            <span className="text-yellow-400">zatca.</span>
                            <span className="text-yellow-300">bzsconnect</span>
                            <span className="text-yellow-400">.com</span>
                            <span className="text-green-400">/api</span>
                            <span className="text-blue-400">/compliance</span>
                        </code>
                    </pre>
                </div>

                <div className="mb-4">
                    <h3 className="text-xl font-semibold mb-1">Options</h3>
                    <p className='text-sm text-gray-600 mb-2'>You can find Authorization Credentials from here: <Link to="/dashboard/authorization" className='pl-2 text-blue-500 underline'>Click here</Link></p>
                    <pre className="bg-neutral-800 p-4 rounded text-gray-200 whitespace-pre-wrap">
                        <code>
                            <span className="text-green-400">const</span> <span className="text-blue-400">options</span> = {'{'}
                            <br />
                            {'\u00A0\u00A0\u00A0\u00A0'}<span className="text-yellow-400">method</span>: <span className="text-red-400">'POST'</span>,
                            <br />
                            {'\u00A0\u00A0\u00A0\u00A0'}<span className="text-yellow-400">headers</span>: {'{'}
                            <br />
                            {'\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0'}<span className="text-yellow-400">'Content-Type'</span>: <span className="text-red-400">'application/json'</span>,
                            <br />
                            {'\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0'}<span className="text-yellow-400">'Authorization'</span>: <span className="text-red-400">'Basic '</span> + <span className="text-blue-400"></span>(<span className="text-red-400">'username:password'</span>),
                            <br />
                            {'\u00A0\u00A0\u00A0\u00A0'}{'}'}
                            <br />
                            {'}'}
                        </code>
                    </pre>

                </div>

                <div className="mb-4">
                    <div className="mb-4">
                        <h3 className="text-xl font-semibold mb-2">Parameters <span className="text-red-500 font-normal text-sm">required</span></h3>
                        <p className='text-sm text-gray-600 mb-2'>Send these data in req.body.</p>
                        <pre className="bg-neutral-800 p-4 rounded text-gray-200 whitespace-pre-wrap">
                            <code>
                                <span className="text-blue-400">{'{ '}</span>
                                <span className="text-green-400">binarySecurityToken, secret, base64Invoice</span>
                                <span className="text-blue-400">{' },'}</span>
                            </code>
                        </pre>
                    </div>
                </div>

                <div className="mb-4">
                    <h3 className="text-xl font-semibold mb-2">Response</h3>
                </div>
                <div className="space-y-4 p-4 bg-neutral-800 rounded text-gray-200">
                    <pre className="bg-neutral-800 p-4 rounded text-gray-200 whitespace-pre-wrap">
                        <code>
                            <span className="text-red-400">{'{ '}</span>
                            <span className="text-yellow-400">uuid </span>
                            <span className="text-yellow-400">: </span>
                            <span className="text-green-400">{`"1E9E5C29-CBF7-3277-E063-04201FACA485"`}</span>
                            <span className="text-gray-400">, </span>
                            <br />
                            <span className="text-yellow-400">invoiceHash </span>
                            <span className="text-yellow-400">: </span>
                            <span className="text-green-400">{`"NWp4iuzbtgb/9trnOag4m3oAYMqH2CSRdHTi2QaGFdE="`}</span>
                            <span className="text-gray-400">, </span>
                            <br />
                            <span className="text-yellow-400">compliance </span>
                            <span className="text-yellow-400">: </span>
                            <span className="text-red-400">{'{ '}</span>
                            <br />
                            <span className="pl-4 text-yellow-400">validationResults </span>
                            <span className="text-yellow-400">: </span>
                            <span className="text-red-400">{'{ '}</span>
                            <br />
                            <span className="pl-8 text-yellow-400">infoMessages </span>
                            <span className="text-yellow-400">: </span>
                            <span className="text-red-400">{'[ '}</span>
                            <br />
                            <span className="pl-12 text-red-400">{'{ '}</span>
                            <span className="pl-12 text-yellow-400">type </span>
                            <span className="text-yellow-400">: </span>
                            <span className="text-green-400">{`"INFO"`}</span>
                            <span className="text-gray-400">, </span>
                            <br />
                            <span className="pl-12 text-yellow-400">code </span>
                            <span className="text-yellow-400">: </span>
                            <span className="text-green-400">{`"XSD_ZATCA_VALID"`}</span>
                            <span className="text-gray-400">, </span>
                            <br />
                            <span className="pl-12 text-yellow-400">category </span>
                            <span className="text-yellow-400">: </span>
                            <span className="text-green-400">{`"XSD validation"`}</span>
                            <span className="text-gray-400">, </span>
                            <br />
                            <span className="pl-12 text-yellow-400">message </span>
                            <span className="text-yellow-400">: </span>
                            <span className="text-green-400">{`"Complied with UBL 2.1 standards in line with ZATCA specifications"`}</span>
                            <span className="text-gray-400">, </span>
                            <br />
                            <span className="pl-12 text-yellow-400">status </span>
                            <span className="text-yellow-400">: </span>
                            <span className="text-green-400">{`"PASS"`}</span>
                            <br />
                            <span className="pl-12 text-red-400">{'} '}</span>
                            <span className="text-red-400">{'] '}</span>
                            <span className="text-gray-400">, </span>
                            <br />
                            <span className="pl-8 text-yellow-400">warningMessages </span>
                            <span className="text-yellow-400">: </span>
                            <span className="text-red-400">{'[ ] '}</span>
                            <span className="text-gray-400">, </span>
                            <br />
                            <span className="pl-8 text-yellow-400">errorMessages </span>
                            <span className="text-yellow-400">: </span>
                            <span className="text-red-400">{'[ ] '}</span>
                            <span className="text-gray-400">, </span>
                            <br />
                            <span className="pl-8 text-yellow-400">status </span>
                            <span className="text-yellow-400">: </span>
                            <span className="text-green-400">{`"PASS"`}</span>
                            <br />
                            <span className="pl-4 text-red-400">{'} '}</span>
                            <span className="text-gray-400">, </span>
                            <br />
                            <span className="pl-4 text-yellow-400">reportingStatus </span>
                            <span className="text-yellow-400">: </span>
                            <span className="text-green-400">{`null`}</span>
                            <span className="text-gray-400">, </span>
                            <br />
                            <span className="pl-4 text-yellow-400">clearanceStatus </span>
                            <span className="text-yellow-400">: </span>
                            <span className="text-green-400">{`"CLEARED"`}</span>
                            <span className="text-gray-400">, </span>
                            <br />
                            <span className="pl-4 text-yellow-400">qrSellertStatus </span>
                            <span className="text-yellow-400">: </span>
                            <span className="text-green-400">{`null`}</span>
                            <span className="text-gray-400">, </span>
                            <br />
                            <span className="pl-4 text-yellow-400">qrBuyertStatus </span>
                            <span className="text-yellow-400">: </span>
                            <span className="text-green-400">{`null`}</span>
                            <br />
                            <span className="text-red-400">{'} '}</span>
                        </code>
                    </pre>
                </div>
            </div>
        </div>
    );
};

export default Compliance;