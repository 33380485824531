import React from 'react'

function CTA() {
    return (
        <div className='bg-[#071C40] w-full my-12'>
            <div className='max-w-[80rem] mx-auto flex items-center justify-between py-16'>
                <div>
                    <h1 className="mb-1 text-4xl font-semibold text-gray-200 tracking-tight">We are here for your assistance!</h1>
                    <p className="text-sm text-gray-200">100% ZATCA Compliance: A Saudi-based solution tailored for Saudi businesses.</p>
                </div>

                <div className='flex items-center justify-center space-x-4 w-1/2'>
                    <div className='w-full h-32 bg-blue-50 px-6  border-slate-200 border text-center space-y-4 flex flex-col items-center justify-center rounded'>
                        <i class="fa-duotone fa-light fa-user-headset text-4xl text-[#071C40]"></i>
                        <p className="text-gray-700 font-medium text-center">Submit a Report</p>
                    </div>
                    <div className='w-full h-32 bg-blue-50 px-6  border-slate-200 border text-center space-y-4 flex flex-col items-center justify-center rounded'>
                        <i class="fa-duotone fa-light fa-messages-question text-4xl text-[#071C40]"></i>
                        <p className="text-gray-700 font-medium text-center">FAQs</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CTA