import React, { useState } from 'react';

const FAQs = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };

    const faqData = [
        {
            question: 'What is ZATCA? (Fatoora)',
            answer: (
                <>
                    <a href="/" className='text-blue-600 underline'>ZATCA</a> is an invoicing and taxation solution that assists Saudi businesses in complying with the Zakat, Tax, and Customs Authority (<a href="/" className='text-blue-600 underline'>ZATCA</a>) regulations. It streamlines tax documentation, e-invoicing, and operational processes to ensure efficiency and regulatory compliance.
                </>
            )
        },
        {
            question: 'What features does ZATCA offer?',
            answer: (
                <>
                    <a href="/" className='text-blue-600 underline'>ZATCA</a> provides a variety of features designed to meet the needs of Saudi Arabia businesses, including:
                    <ul>
                        <li>Seamless integration with ERP systems and POS solutions.</li>
                        <li>Compliance with Phase 2 <a href="/" className='text-blue-600 underline'>ZATCA</a> e-invoicing requirements.</li>
                        <li>Automated billing and tax documentation.</li>
                        <li>User-friendly dashboards for efficient invoice (Fatoora) management.</li>
                        <li>Support for multiple currencies and languages.</li>
                    </ul>
                </>
            )
        },
        {
            question: 'How is ZATCA useful for me?',
            answer: (
                <>
                    <a href="/" className='text-blue-600 underline'>ZATCA</a> helps your business comply with Saudi Arabia's e-invoicing regulations and enhances operational efficiency. It reduces risks related to tax and income documentation, streamlines billing processes, and facilitates easy access to data for audits or reporting.
                </>
            )
        },
        {
            question: 'What type of businesses does ZATCA support?',
            answer: (
                <>
                    <a href="/" className='text-blue-600 underline'>ZATCA</a> supports all types of Saudi businesses, from small startups to large enterprises. Whether you run a retail store, a contracting business, or offer professional services, <a href="/" className='text-blue-600 underline'>ZATCA</a>'s flexible e-invoicing solutions cater to your specific requirements.
                </>
            )
        },
        {
            question: 'How does the contracting business benefit from ZATCA?',
            answer: (
                <>
                    <a href="/" className='text-blue-600 underline'>ZATCA</a> supports contracting businesses to streamline invoicing and billing processes while ensuring compliance with Phase 2 <a href="/" className='text-blue-600 underline'>ZATCA</a> e-invoicing regulations. It simplifies tax calculations, automates invoice generation, and integrates seamlessly with existing ERP systems, making financial management more efficient.
                </>
            )
        },
        {
            question: 'Is ZATCA secure?',
            answer: (
                <>
                    Yes, <a href="/" className='text-blue-600 underline'>ZATCA</a> is a secure platform designed to protect your data. It adheres to stringent security protocols and ensures all tax and invoice documentation is stored safely, giving you peace of mind while managing your business.
                </>
            )
        },
        {
            question: 'Does ZATCA have a payroll system?',
            answer: (
                <>
                    Currently, <a href="/" className='text-blue-600 underline'>ZATCA</a> focuses on providing e-invoicing, billing, and tax documentation services for Saudi businesses. While it does not include a payroll system, it can integrate with ERP solutions that manage payroll.
                </>
            )
        },
        {
            question: 'I offer services and not products, does the ZATCA suit me?',
            answer: (
                <>
                    Yes, <a href="/" className='text-blue-600 underline'>ZATCA</a> is an ideal platform for service-oriented businesses. The platform supports invoicing for service-based transactions, ensuring compliance with <a href="/" className='text-blue-600 underline'>ZATCA</a> e-invoicing regulations and simplifying billing for non-product-based businesses.
                </>
            )
        }
    ];

    return (
        <section className="relative py-20 overflow-hidden bg-blue-50">
            <div className="relative container px-4 mx-auto">
                <div className="max-w-7xl mx-auto">
                    {faqData.map((faq, index) => (
                        <div
                            key={index}
                            className="flex cursor-pointer mb-8 p-8 group w-full items-start justify-between rounded bg-white border border-slate-200 text-left"
                            onClick={() => toggleAccordion(index)}
                        >
                            <div className="max-w-xl pr-5">
                                <h3 className={`text-xl font-semibold ${activeIndex === index ? 'text-blue-900' : 'text-black'}`}>
                                    {faq.question}
                                </h3>
                                <div
                                    style={{
                                        height: activeIndex === index ? 'auto' : '0',
                                        overflow: 'hidden',
                                        transition: 'height 0.5s',
                                    }}
                                >
                                    <p className="mt-3 text-lg text-gray-500">{faq.answer}</p>
                                </div>
                            </div>
                            <div>
                                <span
                                    className={`relative top-4 ${activeIndex === index ? 'hidden' : ''}`}
                                >
                                    <svg width="17" height="3" viewBox="0 0 17 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M15.4619 0.045166H1.46194C1.19673 0.045166 0.942374 0.150523 0.754838 0.338059C0.567302 0.525596 0.461945 0.77995 0.461945 1.04517C0.461945 1.31038 0.567302 1.56474 0.754838 1.75227C0.942374 1.93981 1.19673 2.04517 1.46194 2.04517H15.4619C15.7272 2.04517 15.9815 1.93981 16.1691 1.75227C16.3566 1.56474 16.4619 1.31038 16.4619 1.04517C16.4619 0.77995 16.3566 0.525596 16.1691 0.338059C15.9815 0.150523 15.7272 0.045166 15.4619 0.045166Z"
                                            fill="blue"
                                        />
                                    </svg>
                                </span>
                                <span
                                    className={`relative top-2 ${activeIndex === index ? '' : 'hidden'}`}
                                >
                                    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M15.4619 7H9.46194V1C9.46194 0.734784 9.35659 0.48043 9.16905 0.292893C8.98151 0.105357 8.72716 0 8.46194 0C8.19673 0 7.94237 0.105357 7.75484 0.292893C7.5673 0.48043 7.46194 0.734784 7.46194 1V7H1.46194C1.19673 7 0.942374 7.10536 0.754838 7.29289C0.567302 7.48043 0.461945 7.73478 0.461945 8C0.461945 8.26522 0.567302 8.51957 0.754838 8.70711C0.942374 8.89464 1.19673 9 1.46194 9H7.46194V15C7.46194 15.2652 7.5673 15.5196 7.75484 15.7071C7.94237 15.8946 8.19673 16 8.46194 16C8.72716 16 8.98151 15.8946 9.16905 15.7071C9.35659 15.5196 9.46194 15.2652 9.46194 15V9H15.4619C15.7272 9 15.9815 8.89464 16.1691 8.70711C16.3566 8.51957 16.4619 8.26522 16.4619 8C16.4619 7.73478 16.3566 7.48043 16.1691 7.29289C15.9815 7.10536 15.7272 7 15.4619 7Z"
                                            fill="black"
                                        />
                                    </svg>
                                </span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default FAQs;
