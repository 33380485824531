import React, { useState } from 'react';
import { useAuth } from '../../../../context/data';
import { useUser } from '@clerk/clerk-react';

const FileUploadInput = ({ onFileChange, acceptedFileTypes = '.cnf' }) => {
    const [dragActive, setDragActive] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const inputRef = React.useRef(null);

    const handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);

        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            processFile(e.dataTransfer.files[0]);
        }
    };

    const handleChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            processFile(e.target.files[0]);
        }
    };

    const processFile = (file) => {
        if (file.name.toLowerCase().endsWith(acceptedFileTypes.replace('.', ''))) {
            setSelectedFile(file);
            onFileChange(file);
        } else {
            alert(`Please upload a file with ${acceptedFileTypes} extension.`);
        }
    };

    const handleClick = () => {
        inputRef.current.click();
    };

    return (
        <div
            className={`
                w-full p-8 bg-white border-2 border-dashed rounded-lg text-center transition-all duration-300
                ${dragActive
                    ? 'border-blue-500 bg-blue-50'
                    : 'border-gray-300 hover:border-blue-500 hover:bg-blue-50'
                }
            `}
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
        >
            <input
                type="file"
                ref={inputRef}
                onChange={handleChange}
                accept={acceptedFileTypes}
                className="hidden"
            />

            <div
                onClick={handleClick}
                className="cursor-pointer flex flex-col items-center justify-center space-y-4"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="48"
                    height="48"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="text-gray-500 hover:text-blue-500 transition-colors"
                >
                    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
                    <polyline points="17 8 12 3 7 8" />
                    <line x1="12" y1="3" x2="12" y2="15" />
                </svg>

                <div>
                    <p className="text-gray-600 font-semibold">
                        {selectedFile
                            ? `Selected: ${selectedFile.name}`
                            : 'Drag & Drop .cnf File or Click to Upload'}
                    </p>
                    <p className="text-sm text-gray-500 mt-2">
                        Maximum file size: 5MB
                    </p>
                </div>
            </div>
        </div>
    );
};

const CSRForm = () => {
    const { user } = useUser();
    const username = user.primaryEmailAddress.emailAddress;
    const { authURL, ID } = useAuth();
    const password = ID;
    const [file, setFile] = useState(null);
    const [response, setResponse] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);
        setResponse(null);
        setIsLoading(true);

        if (!file) {
            setError({ message: 'Please upload a .cnf file.' });
            setIsLoading(false);
            return;
        }

        const encodedCredentials = btoa(`${username}:${password}`);
        const formData = new FormData();
        formData.append('cnfFile', file);

        try {
            const res = await fetch(`${authURL}/taxpayer`, {
                method: 'POST',
                headers: {
                    'Authorization': `Basic ${encodedCredentials}`,
                },
                body: formData,
            });

            const data = await res.json();

            if (!res.ok) {
                setError({ status: res.status, message: data.message || 'An error occurred', details: data.error });
            } else {
                setResponse({
                    status: res.status,
                    message: 'CSR and private key generated successfully!',
                    csr: data.csr,
                    pem: data.pem,
                });
            }
        } catch (err) {
            setError({ status: 500, message: 'An unexpected error occurred', details: err.message });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div>
            <form onSubmit={handleSubmit} className="space-y-6">
                <div>
                    <FileUploadInput
                        onFileChange={(uploadedFile) => setFile(uploadedFile)}
                    />
                </div>

                <button
                    type="submit"
                    className="w-full py-3 bg-gradient-to-b from-blue-500 to-blue-600 text-white font-semibold rounded-md 
                        hover:shadow-lg transition duration-200 
                        disabled:opacity-50 disabled:cursor-not-allowed"
                    disabled={isLoading}
                >
                    {isLoading ? 'Generating...' : 'Generate CSR'}
                </button>
            </form>

            {response && (
                <div className="mt-6 p-4 border border-green-400 rounded-md bg-green-50">
                    <h3 className="text-lg font-semibold text-green-700">Success (HTTP {response.status})</h3>
                    <p className="text-green-700 mb-2">{response.message}</p>
                    <div className="mb-2">
                        <strong>CSR:</strong>
                        <pre className="text-sm bg-gray-100 p-2 rounded-md overflow-auto max-h-full">
                            {response.csr}
                        </pre>
                    </div>
                    <div>
                        <strong>Private Key:</strong>
                        <pre className="text-sm bg-gray-100 p-2 rounded-md overflow-auto max-h-40">
                            {response.pem}
                        </pre>
                    </div>
                </div>
            )}

            {error && (
                <div className="mt-6 p-4 border border-red-400 rounded-md bg-red-50">
                    <h3 className="text-lg font-semibold text-red-700">Error</h3>
                    <p className="text-red-700 mb-2">{error.message}</p>
                    {error.details && (
                        <pre className="text-sm bg-gray-100 p-2 rounded-md overflow-auto">
                            {error.details}
                        </pre>
                    )}
                </div>
            )}
        </div>
    );
};

export default CSRForm;