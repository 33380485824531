import React, { useRef } from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import logo from './Picture1.png'

const SalarySlip = () => {
    const salarySlipRef = useRef(null);

    const companyDetails = {
        name: 'TechInnovate Solutions Pvt Ltd',
        address: '123 Innovation Park, Tech City, IN 560001',
        gstin: 'GSTIN: 07AAPCA1234C1Z1',
    };

    const employeeDetails = {
        name: 'Mohammad Athar Raza',
        employeeId: '3310017245727',
        designation: 'MERN Stack & Next JS Developer',
        department: 'WEB  Development',
        pan: '0321 767 8097',
        email: 'atharaza.dev@gmail.com',
        logo: '/api/placeholder/200/200'
    };

    const salaryDetails = {
        month: 'January 2024',
        paydays: 31,
        bankName: 'State Bank of India',
        accountNo: 'XXXXXX9876'
    };

    const earnings = [
        { head: 'Basic Salary', amount: 50000.00 },
        { head: 'House Rent Allowance', amount: 0 },
        { head: 'Conveyance Allowance', amount: 0 },
        { head: 'Special Allowance', amount: 0 }
    ];

    const deductions = [
        { head: 'Provident Fund', amount: 0 },
        { head: 'Professional Tax', amount: 0 },
        { head: 'Income Tax', amount: 0 }
    ];

    const calculateTotal = (items) => items.reduce((sum, item) => sum + item.amount, 0);
    const totalEarnings = calculateTotal(earnings);
    const totalDeductions = calculateTotal(deductions);
    const netPayable = totalEarnings - totalDeductions;

    const downloadPDF = () => {
        const input = salarySlipRef.current;
        html2canvas(input, {
            scale: 2,
            useCORS: true,
            allowTaint: true
        }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF({
                orientation: 'portrait',
                unit: 'mm',
                format: 'a4'
            });

            const imgProps = pdf.getImageProperties(imgData);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

            pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
            pdf.save(`Salary_Slip_${employeeDetails.name}_${salaryDetails.month}.pdf`);
        });
    };

    return (
        <div className="container mx-auto p-4 mt-24">
            <div className="flex justify-end mb-4">
                <button
                    onClick={downloadPDF}
                    className="bg-gray-800 text-white px-4 py-2 rounded-sm hover:bg-gray-700 transition-colors flex items-center"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                    </svg>
                    Download PDF
                </button>
            </div>

            <div ref={salarySlipRef} className="max-w-4xl mx-auto bg-white shadow-md rounded-sm border border-gray-200 font-sans">
                <div className="bg-gray-100 border-b border-gray-200 p-6">
                    <div className="flex justify-between items-start">
                        <div className="flex flex-col items-start space-y-4">
                            <img
                                src={logo}
                                alt="Company Logo"
                                className="w-52 object-cover"
                            />
                            <div className='space-y-1'>
                                <p className="text-sm text-gray-800">2nd Floor, United Plaza, Sheikhupura Road, near Qamar Garden</p>
                                <p className="text-sm text-gray-800">Faisalabad - 38000</p>
                                <p className="text-sm text-gray-800">0324 7730410</p>
                                <p className="text-sm text-gray-800">info@bzsoftech.com</p>
                            </div>
                        </div>
                        <div className="text-right">
                            <h2 className="text-xl font-bold text-gray-700">Salary Slip Statement</h2>
                            <p className="text-lg font-semibold text-gray-600">December 2024</p>
                        </div>
                    </div>
                </div>

                <div className="p-6">
                    <div className="grid grid-cols-2 gap-4 mb-6 bg-gray-50 p-4 rounded-sm">
                        <div>
                            <div className="grid grid-cols-3 gap-2 text-sm">
                                <span className="font-medium text-gray-600">Name:</span>
                                <span className="col-span-2">{employeeDetails.name}</span>
                                <span className="font-medium text-gray-600">Employee ID:</span>
                                <span className="col-span-2">{employeeDetails.employeeId}</span>
                                <span className="font-medium text-gray-600">Designation:</span>
                                <span className="col-span-2">{employeeDetails.designation}</span>
                            </div>
                        </div>
                        <div className="text-right">
                            <div className="grid grid-cols-3 gap-2 text-sm justify-end">
                                <span className="font-medium text-gray-600">Email:</span>
                                <span className="col-span-2">{employeeDetails.email}</span>
                                <span className="font-medium text-gray-600">Phone:</span>
                                <span className="col-span-2">{employeeDetails.pan}</span>
                            </div>
                        </div>
                    </div>

                    <div className="grid grid-cols-2 gap-6">
                        <div className="border rounded-sm">
                            <div className="bg-gray-100 border-b p-3">
                                <h3 className="text-sm font-semibold text-gray-700 text-center">Earnings</h3>
                            </div>
                            <table className="w-full text-sm">
                                <tbody>
                                    {earnings.map((item, index) => (
                                        <tr key={index} className="border-b last:border-b-0">
                                            <td className="p-3">{item.head}</td>
                                            <td className="p-3 text-right">PK R{item.amount.toLocaleString('en-IN', { minimumFractionDigits: 2 })}</td>
                                        </tr>
                                    ))}
                                    <tr className="bg-gray-50 font-semibold">
                                        <td className="p-3">Total Earnings</td>
                                        <td className="p-3 text-right">PKR {totalEarnings.toLocaleString('en-IN', { minimumFractionDigits: 2 })}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="border rounded-sm">
                            <div className="bg-gray-100 border-b p-3">
                                <h3 className="text-sm font-semibold text-gray-700 text-center">Deductions</h3>
                            </div>
                            <table className="w-full text-sm">
                                <tbody>
                                    {deductions.map((item, index) => (
                                        <tr key={index} className="border-b last:border-b-0">
                                            <td className="p-3">{item.head}</td>
                                            <td className="p-3 text-right">PKR {item.amount.toLocaleString('en-IN', { minimumFractionDigits: 2 })}</td>
                                        </tr>
                                    ))}
                                    <tr className="bg-gray-50 font-semibold">
                                        <td className="p-3">Total Deductions</td>
                                        <td className="p-3 text-right">PKR {totalDeductions.toLocaleString('en-IN', { minimumFractionDigits: 2 })}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="mt-6 pt-4 border-t flex justify-between items-center">
                        <div>
                            <p className="font-semibold text-gray-800">
                                Net Payable: <span className='text-xl underline font-bold'>PKR{netPayable.toLocaleString('en-IN', { minimumFractionDigits: 2 })}</span>
                            </p>
                            <p className="text-sm text-gray-600">Days Worked: 31</p>
                        </div>
                    </div>

                    <div className="mt-6 pt-4 border-t flex justify-between items-center">
                        <div className="w-1/3">
                            <div className="border-t border-gray-300 pt-2 mt-10">
                                <p className="text-sm text-gray-600 text-center">Employee Signature</p>
                            </div>
                        </div>
                        <div className="w-1/3 text-right">
                            <div className="border-t border-gray-300 pt-2 mt-10">
                                <p className="text-sm text-gray-600 text-center">Admin Signature</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-gray-100 p-3 text-center text-sm text-gray-600 border-t">
                    Generated by BZSoftech Finance System | Version 1.0 | © 2022
                </div>
            </div>
        </div>
    );
};

export default SalarySlip;