import React, { useState } from 'react';
import Sim from '../../assets/sim1.png';
import Sim2 from '../../assets/sim2.png';
import stock from '../../assets/stock.avif';

const ServicesTabs = () => {
    const [activeTab, setActiveTab] = useState('tab1');

    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
    };

    return (
        <div className='bg-blue-50'>
            <section className="max-w-[80rem] px-8 mx-auto py-12 overflow-hidden">
                <div className="flex flex-wrap -mx-4 mb-8 items-center">
                    <div className="w-full lg:w-2/3 xl:w-1/2 px-4 mb-12 lg:mb-0">
                        <div>
                            <h1 className="font-heading mb-4 logo text-5xl xs:text-5xl md:text-6xl font-bold text-gray-900">
                                ZATCA e-Services
                            </h1>
                        </div>
                    </div>
                    <div className="w-full lg:w-1/3 xl:w-1/2 px-4">
                        <div className="max-w-sm lg:ml-auto">
                            <p className="text-lg text-gray-500">
                                Explore our comprehensive range of e-invoice and products tailored for Saudi businesses to meet your needs and drive success.
                            </p>
                        </div>
                    </div>
                </div>

                <div className='flex space-x-8 items-start justify-between'>
                    <nav className="relative w-1/4 flex flex-col justify-start space-y-4 overflow-hidden">
                        <button
                            className={`relative max-w-2xl group inline-block xs:w-auto py-4 px-6 font-semibold rounded-md overflow-hidden transition duration-500 ${activeTab === 'tab1' ? 'bg-gray-900 text-white' : 'bg-white text-blue-900 hover:text-white'}`}
                            onClick={() => handleTabClick('tab1')}
                            aria-selected={activeTab === 'tab1'}
                            role='tab'>
                            <div className="absolute top-0 right-full w-full h-full bg-gray-900 transform group-hover:translate-x-full group-hover:scale-105 transition duration-500"></div>
                            <div className="relative flex items-center justify-between">
                                <h1 className="mr-4">On Site Simulation</h1>
                                <span>
                                    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.92 0.62C12.8185 0.375651 12.6243 0.181475 12.38 0.0799999C12.2598 0.028759 12.1307 0.00157999 12 0H2C1.73478 0 1.48043 0.105357 1.29289 0.292893C1.10536 0.48043 1 0.734784 1 1C1 1.26522 1.10536 1.51957 1.29289 1.70711C1.48043 1.89464 1.73478 2 2 2H9.59L1.29 10.29C1.19627 10.383 1.12188 10.4936 1.07111 10.6154C1.02034 10.7373 0.994202 10.868 0.994202 11C0.994202 11.132 1.02034 11.2627 1.07111 11.3846C1.12188 11.5064 1.19627 11.617 1.29 11.71C1.38296 11.8037 1.49356 11.8781 1.61542 11.9289C1.73728 11.9797 1.86799 12.0058 2 12.0058C2.13201 12.0058 2.26272 11.9797 2.38458 11.9289C2.50644 11.8781 2.61704 11.8037 2.71 11.71L11 3.41V11C11 11.2652 11.1054 11.5196 11.2929 11.7071C11.4804 11.8946 11.7348 12 12 12C12.2652 12 12.5196 11.8946 12.7071 11.7071C12.8946 11.5196 13 11.2652 13 11V1C12.9984 0.869323 12.9712 0.740222 12.92 0.62Z" fill="currentColor"></path>
                                    </svg>
                                </span>
                            </div>
                        </button>

                        <button
                            className={`relative max-w-2xl group inline-block xs:w-auto py-4 px-6 font-semibold rounded-md overflow-hidden transition duration-500 ${activeTab === 'tab2' ? 'bg-gray-900 text-white' : 'bg-white text-blue-900 hover:text-white'}`}
                            onClick={() => handleTabClick('tab2')}
                            aria-selected={activeTab === 'tab2'}
                            role='tab'>
                            <div className="absolute top-0 right-full w-full h-full bg-gray-900 transform group-hover:translate-x-full group-hover:scale-105 transition duration-500"></div>
                            <div className="relative flex items-center justify-between">
                                <h1 className="mr-4">Embeded Solution</h1>
                                <span>
                                    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.92 0.62C12.8185 0.375651 12.6243 0.181475 12.38 0.0799999C12.2598 0.028759 12.1307 0.00157999 12 0H2C1.73478 0 1.48043 0.105357 1.29289 0.292893C1.10536 0.48043 1 0.734784 1 1C1 1.26522 1.10536 1.51957 1.29289 1.70711C1.48043 1.89464 1.73478 2 2 2H9.59L1.29 10.29C1.19627 10.383 1.12188 10.4936 1.07111 10.6154C1.02034 10.7373 0.994202 10.868 0.994202 11C0.994202 11.132 1.02034 11.2627 1.07111 11.3846C1.12188 11.5064 1.19627 11.617 1.29 11.71C1.38296 11.8037 1.49356 11.8781 1.61542 11.9289C1.73728 11.9797 1.86799 12.0058 2 12.0058C2.13201 12.0058 2.26272 11.9797 2.38458 11.9289C2.50644 11.8781 2.61704 11.8037 2.71 11.71L11 3.41V11C11 11.2652 11.1054 11.5196 11.2929 11.7071C11.4804 11.8946 11.7348 12 12 12C12.2652 12 12.5196 11.8946 12.7071 11.7071C12.8946 11.5196 13 11.2652 13 11V1C12.9984 0.869323 12.9712 0.740222 12.92 0.62Z" fill="currentColor"></path>
                                    </svg>
                                </span>
                            </div>
                        </button>

                        <button
                            className={`relative max-w-2xl group inline-block xs:w-auto py-4 px-6 font-semibold rounded-md overflow-hidden transition duration-500 ${activeTab === 'tab3' ? 'bg-gray-900 text-white' : 'bg-white text-blue-900 hover:text-white'}`}
                            onClick={() => handleTabClick('tab3')}
                            aria-selected={activeTab === 'tab3'}
                            role='tab'>
                            <div className="absolute top-0 right-full w-full h-full bg-gray-900 transform group-hover:translate-x-full group-hover:scale-105 transition duration-500"></div>
                            <div className="relative flex items-center justify-between">
                                <h1 className="mr-4">On Premises Solution</h1>
                                <span>
                                    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.92 0.62C12.8185 0.375651 12.6243 0.181475 12.38 0.0799999C12.2598 0.028759 12.1307 0.00157999 12 0H2C1.73478 0 1.48043 0.105357 1.29289 0.292893C1.10536 0.48043 1 0.734784 1 1C1 1.26522 1.10536 1.51957 1.29289 1.70711C1.48043 1.89464 1.73478 2 2 2H9.59L1.29 10.29C1.19627 10.383 1.12188 10.4936 1.07111 10.6154C1.02034 10.7373 0.994202 10.868 0.994202 11C0.994202 11.132 1.02034 11.2627 1.07111 11.3846C1.12188 11.5064 1.19627 11.617 1.29 11.71C1.38296 11.8037 1.49356 11.8781 1.61542 11.9289C1.73728 11.9797 1.86799 12.0058 2 12.0058C2.13201 12.0058 2.26272 11.9797 2.38458 11.9289C2.50644 11.8781 2.61704 11.8037 2.71 11.71L11 3.41V11C11 11.2652 11.1054 11.5196 11.2929 11.7071C11.4804 11.8946 11.7348 12 12 12C12.2652 12 12.5196 11.8946 12.7071 11.7071C12.8946 11.5196 13 11.2652 13 11V1C12.9984 0.869323 12.9712 0.740222 12.92 0.62Z" fill="currentColor"></path>
                                    </svg>
                                </span>
                            </div>
                        </button>

                    </nav>

                    <div className='w-3/4'>
                        <div className={`tab-content ${activeTab === 'tab1' ? 'block' : 'hidden'}`}>
                            <div className='w-full h-full bg-white rounded border border-solid border-gray-200 flex flex-col space-y-4 items-start justify-center p-8'>
                                <h1 className='text-3xl font-light'>On Site Simulation</h1>
                                <h1 className='text-gray-700'>The ZATCA Services provide easy to use and implement OnSite Simulation for each ZATCA e-invoicing and Fatoora solutions within under one single roof. It provides tons of features like Hashing, Signing, Generating QR Code, Private Keys and Certificates along with Onboarding Devices and Clearance and reporting of the Invoices.</h1>
                                <img src={Sim} alt="err" className='border-2 border-slate-200' />
                            </div>
                        </div>

                        <div className={`tab-content ${activeTab === 'tab2' ? 'block' : 'hidden'}`}>
                            <div className='w-full h-full bg-white rounded border border-solid border-gray-200 flex flex-col space-y-4 items-start justify-center p-8'>
                                <h1 className='text-3xl font-light'>Embeded Solution</h1>
                                <h1 className='text-gray-700'>The ZATCA Services provide easy to use and integrate Embeded Solution for each ZATCA e-invoicing and Fatoora solutions through our secured and authorized APIs within your legacy system. It provides proper security and authentication along with strong SSL around it.</h1>
                                <img src={Sim2} alt="err" className='border-2 border-slate-200' />
                            </div>
                        </div>

                        <div className={`tab-content ${activeTab === 'tab3' ? 'block' : 'hidden'}`}>
                            <div className='w-full h-full bg-white rounded border border-solid border-gray-200 flex flex-col space-y-4 items-start justify-center p-8'>
                                <h1 className='text-3xl font-light'>On Premises Solution</h1>
                                <h1 className='text-gray-700'>The ZATCA Services provide a fully developed and secured, fast pre built solution for each ZATCA e-invoicing and Fatoora solutions through our secured and authorized Oacle APEX. It provides proper security and authentication along with strong SSL around it.</h1>
                                <img src={stock} alt="err" className='w-96' />
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </div>
    );
};

export default ServicesTabs;