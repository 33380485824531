import React, { useState, useEffect } from "react";
import { toast, Toaster } from "react-hot-toast";
import { Helmet } from 'react-helmet';

const loadRecaptchaScript = () => {
  const script = document.createElement("script");
  script.src = `https://www.google.com/recaptcha/api.js?render=6LcMU1MqAAAAABzpMgT97VYjrgGGgUINweVTELOf`;
  script.async = true;
  document.body.appendChild(script);
};

const ContactUs = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    message: "",
  });
  const [captchaToken, setCaptchaToken] = useState("");
  const [isSending, setIsSending] = useState(false); // New state to track sending status

  useEffect(() => {
    loadRecaptchaScript();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSending(true); // Set loading state to true when submitting

    if (
      !formData.firstName ||
      !formData.lastName ||
      !formData.email ||
      !formData.message
    ) {
      toast.error("Please fill in all the required fields.");
      setIsSending(false); // Reset loading state if validation fails
      return;
    }

    const data = {
      fullName: `${formData.firstName} ${formData.lastName}`,
      email: formData.email,
      description: formData.message,
      appGenre: 'zatca.co',
    };

    try {
      const response = await fetch("https://admin-bzs-server.vercel.app/admin/auth/send-email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error("Failed to send data");
      }

      toast.success("Successfully submitted - Check your email!", {
        icon: "👏",
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });

      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute("6LcMU1MqAAAAABzpMgT97VYjrgGGgUINweVTELOf", { action: "submit" })
          .then((token) => {
            setCaptchaToken(token);
            setFormData({ firstName: "", lastName: "", email: "", message: "" });
          })
          .catch(() => {
            toast.error("reCAPTCHA verification failed. Please try again.");
          });
      });
    } catch (error) {
      toast.error(error.message || "An unexpected error occurred. Please try again.");
    } finally {
      setIsSending(false); // Reset loading state after submission
    }
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  return (
    <>
      <Helmet>
        <title>
          Contact Us |  Zatca E-Invoicing for Saudi Arabia
        </title>
        <meta
          name="description"
          content="Have questions or need support? Please reach out to us. We're here to help!"
        />
        <meta
          name="keywords"
          content="Contact ZATCA API, ZATCA compliance support, e-invoicing inquiries, digital signing questions, customer support"
        />
        <meta property="og:title" content="Contact Us – ZATCA API" />
        <meta
          property="og:description"
          content="Reach out to ZATCA API for all your compliance needs and inquiries about our services."
        />
        <meta property="og:url" content="https://yourwebsite.com/contact-us" />
        <meta property="og:type" content="website" />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <div className="text-center pt-24 pb-12 bg-[#071C40]">
        <h1 className="text-4xl   text-gray-200 leading-tight">
          Get in
          <span className="text-blue-600 px-2">Touch</span>
          with us
        </h1>
        <p className="mt-4 text-lg text-gray-300 max-w-2xl mx-auto">
          ZATCA APIs Services leads the way in e-invoicing and ERP solutions,
          tailored for Saudi businesses. Our solutions blend simplicity
          with quality.
        </p>
      </div>

      <section class="text-gray-600 body-font relative">
        <div class="absolute inset-0 bg-gray-300">
          <div class="absolute inset-0 bg-gray-300">
            <iframe
              width="100%"
              height="100%"
              title="map"
              src={`https://maps.google.com/maps?q=31.4620802,73.1556677&z=18&t=k&output=embed`}
            ></iframe>
          </div>
        </div>
        <div class="container px-24 py-24 mx-auto flex">
          <div class="lg:w-1/3 md:w-1/2 bg-white rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0 relative z-10 shadow-md">
            <h2 class="text-gray-900 text-lg mb-1 font-medium title-font">Contact Us</h2>
            <p class="leading-relaxed mb-5 text-gray-600">
              Have questions or need support? Reach out to us, we're here to help!
            </p>
            <div class="relative mb-4">
              <label for="email" class="leading-7 text-sm text-gray-600">Email</label>
              <input type="email" id="email" name="email" class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
            </div>
            <div class="relative mb-4">
              <label for="email" class="leading-7 text-sm text-gray-600">Full Name</label>
              <input type="email" id="email" name="email" class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
            </div>
            <div class="relative mb-4">
              <label for="message" class="leading-7 text-sm text-gray-600">Message</label>
              <textarea id="message" name="message" class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"></textarea>
            </div>
            <button class="text-white bg-blue-500 border-0 py-2 px-6 focus:outline-none hover:bg-blue-600 rounded text-lg">Send Inquiry</button>
          </div>
        </div>
      </section>

    </>
  );
};

export default ContactUs;
