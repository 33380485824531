import React from 'react'
import fatoora from '../../assets/fatoora.png'
import sbc from '../../assets/sbc.svg'

function MoreOverSection() {
    return (
        <div className='max-w-[80rem] mx-auto flex items-center justify-center space-x-4'>

            <div className='w-full h-72 rounded  px-6 bg-blue-50 border-slate-200 border flex flex-col items-center justify-center space-y-4'>
                <img src={fatoora} alt="err" className='w-60' />
                <h1 className="mt-2 font-semibold text-xl text-center">
                    E-Invoicing
                </h1>
                <p className="text-gray-600 text-center">
                    Electronic invoicing is a procedure that aims to convert the issuing of paper invoices and notes into an electronic process.
                </p>
            </div>

            <div className='w-full h-96 rounded bg-[#071C40] px-6  border-slate-200 border flex flex-col items-center justify-center space-y-4'>
                <i class="fa-duotone fa-light fa-money-bill-1-wave text-4xl text-gray-200"></i>
                <h1 className="mt-2 font-semibold text-xl text-center text-gray-200">
                    Value Added Tax (VAT)
                </h1>
                <p className="text-gray-200 text-center">
                    Value Added Tax (or VAT) is an indirect tax imposed on all goods and services that are bought and sold by businesses, with a few exceptions. VAT is applied in more than 160 countries around the world as a reliable source of revenue for state budgets.
                </p>
            </div>

            <div className='w-full h-72 rounded bg-blue-50 px-6  border-slate-200 border flex flex-col items-center justify-center space-y-4'>
                <img src={sbc} alt="err" className='w-60' />
                <h1 className="mt-2 font-semibold text-xl text-center">
                    Saudi Business Center
                </h1>
                <p className="text-gray-600 text-center">
                    Your eGate to start and manage all your services with government agencies
                </p>
            </div>

        </div>
    )
}

export default MoreOverSection