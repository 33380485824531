import React from 'react';
import { Link } from 'react-router-dom';

const Clearance = () => {
    return (
        <div className='flex flex-col space-y-8'>

            <div>
                <h1 className='text-5xl font-light mb-2'>Reporting </h1>
                <p className='text-md text-gray-600'>Push your Invoices to check the status Reporting of Invoices..</p>
            </div>


            <div className="w-full">

                <div className="mb-4">
                    <h3 className="text-xl font-semibold mb-2">Endpoint</h3>
                    <p className='text-sm text-gray-600 mb-2'>Upload a Base64 of an XML then it will return the hash of the processed invoice.</p>
                    <pre className="bg-neutral-800 p-4 rounded text-gray-200 whitespace-pre-wrap">
                        <code className="text-blue-400">
                            <span className="text-green-400">https://</span>
                            <span className="text-yellow-400">zatca.</span>
                            <span className="text-yellow-300">bzsconnect</span>
                            <span className="text-yellow-400">.com</span>
                            <span className="text-green-400">/api</span>
                            <span className="text-blue-400">/reporting</span>
                        </code>
                    </pre>
                </div>

                <div className="mb-4">
                    <h3 className="text-xl font-semibold mb-1">Options</h3>
                    <p className='text-sm text-gray-600 mb-2'>You can find Authorization Credentials from here: <Link to="/dashboard/authorization" className='pl-2 text-blue-500 underline'>Click here</Link></p>
                    <pre className="bg-neutral-800 p-4 rounded text-gray-200 whitespace-pre-wrap">
                        <code>
                            <span className="text-green-400">const</span> <span className="text-blue-400">options</span> = {'{'}
                            <br />
                            {'\u00A0\u00A0\u00A0\u00A0'}<span className="text-yellow-400">method</span>: <span className="text-red-400">'POST'</span>,
                            <br />
                            {'\u00A0\u00A0\u00A0\u00A0'}<span className="text-yellow-400">headers</span>: {'{'}
                            <br />
                            {'\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0'}<span className="text-yellow-400">'Content-Type'</span>: <span className="text-red-400">'application/json'</span>,
                            <br />
                            {'\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0'}<span className="text-yellow-400">'Authorization'</span>: <span className="text-red-400">'Basic '</span> + <span className="text-blue-400"></span>(<span className="text-red-400">'username:password'</span>),
                            <br />
                            {'\u00A0\u00A0\u00A0\u00A0'}{'}'}
                            <br />
                            {'}'}
                        </code>
                    </pre>

                </div>

                <div className="mb-4">
                    <div className="mb-4">
                        <h3 className="text-xl font-semibold mb-2">Parameters <span className="text-red-500 font-normal text-sm">required</span></h3>
                        <p className='text-sm text-gray-600 mb-2'>Send these data in req.body.</p>
                        <pre className="bg-neutral-800 p-4 rounded text-gray-200 whitespace-pre-wrap">
                            <code>
                                <span className="text-blue-400">{'{ '}</span>
                                <span className="text-green-400">binarySecurityToken, secret, base64Xml</span>
                                <span className="text-blue-400">{' },'}</span>
                            </code>
                        </pre>
                    </div>
                </div>

                <div className="mb-4">
                    <h3 className="text-xl font-semibold mb-2">Response</h3>
                </div>
                <div className="space-y-4 p-4 bg-neutral-800 rounded text-gray-200">
                    <pre className="bg-neutral-800 p-4 rounded text-gray-200 whitespace-pre-wrap">
                        <code>
                            <span className="text-green-400">const</span> <span className="text-blue-400">validationResult</span> = {'{'}

                            <br />
                            {'\u00A0\u00A0\u00A0\u00A0'}<span className="text-yellow-400">info</span>: {'['}
                            <br />
                            {'\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0'}{'{'}

                            <br />
                            {'\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0'}<span className="text-yellow-400">type</span>: <span className="text-red-400">'INFO'</span>,
                            <br />
                            {'\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0'}<span className="text-yellow-400">code</span>: <span className="text-red-400">'XSD_ZATCA_VALID'</span>,
                            <br />
                            {'\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0'}<span className="text-yellow-400">category</span>: <span className="text-red-400">'XSD validation'</span>,
                            <br />
                            {'\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0'}<span className="text-yellow-400">message</span>: <span className="text-red-400">'Complied with UBL 2.1 standards in line with ZATCA specifications'</span>,
                            <br />
                            {'\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0'}<span className="text-yellow-400">status</span>: <span className="text-green-600">'PASS'</span>
                            <br />
                            {'\u00A0\u00A0\u00A0\u00A0'}{'}'}
                            <br />
                            {'\u00A0\u00A0\u00A0\u00A0'}{']'},
                            <br />
                            {'\u00A0\u00A0\u00A0\u00A0'}<span className="text-yellow-400">warnings</span>: <span className="text-red-400">[]</span>,
                            <br />
                            {'\u00A0\u00A0\u00A0\u00A0'}<span className="text-yellow-400">errors</span>: <span className="text-red-400">[]</span>,
                            <br />
                            {'\u00A0\u00A0\u00A0\u00A0'}<span className="text-yellow-400">overallStatus</span>: <span className="text-green-600">'PASS'</span>,
                            <br />
                            {'\u00A0\u00A0\u00A0\u00A0'}<span className="text-yellow-400">clearanceStatus</span>: <span className="text-green-600">'CLEARED'</span>,
                            <br />
                            {'\u00A0\u00A0\u00A0\u00A0'}<span className="text-yellow-400">clearedInvoice</span>: <span className="text-red-400">'PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4K...'</span>
                            <br />
                            {'}'}
                        </code>
                    </pre>
                </div>
            </div>
        </div>
    );
};

export default Clearance;