import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PaymentMethodSelection from "./PaymentMethodSelection";
import { useUser } from "@clerk/clerk-react";
import { useAuth } from "../../../context/data";

export default function CheckoutPage() {
  const { authURL } = useAuth();
  const location = useLocation();
  const { user } = useUser();
  const navigate = useNavigate();

  const [selectedPlan, setSelectedPlan] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [paymentProof, setPaymentProof] = useState(null);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (location.state && location.state.selectedPlan) {
      setSelectedPlan(location.state.selectedPlan);
    } else {
      navigate("/pricing");
    }
  }, [location.state, navigate]);

  const handlePaymentProofUpload = (file) => {
    setPaymentProof(file);
  };

  const convertFileToBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!paymentProof) {
      setErrors((prev) => ({ ...prev, paymentProof: "Payment proof is required" }));
      return;
    }

    setIsSubmitting(true);

    try {
      // Convert the uploaded file to Base64
      const paymentProofBase64 = await convertFileToBase64(paymentProof);

      const payload = {
        fullName: user.fullName,
        email: user.primaryEmailAddress.emailAddress,
        screenshot: paymentProofBase64,
        package: selectedPlan.title,
      };

      const response = await fetch(`${authURL}/checkout`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();

      if (response.ok) {
        alert("Payment submitted successfully!");
        navigate("/dashboard/checkout-status");
      } else {
        alert(`Error: ${data.message || "Something went wrong!"}`);
      }
    } catch (error) {
      console.error("Error during submission:", error);
      alert("An error occurred. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!selectedPlan) return <div>Loading...</div>;

  return (
    <>
      <div className="text-center pt-24 pb-12 bg-[#071C40]">
        <h1 className="text-4xl text-gray-200 leading-tight">
          Checkout your Order
        </h1>
      </div>
      <div className="container mx-auto px-4 py-12 max-w-7xl">
        <form className="grid grid-cols-1 lg:grid-cols-2 gap-8" onSubmit={handleSubmit}>
          <div className="bg-white rounded-lg p-6 md:p-8 border border-slate-200">
            <h2 className="text-2xl font-bold text-gray-800 mb-6">
              Checkout Details
            </h2>
            <div className="space-y-4">
              <div className="flex items-center space-x-4 justify-center w-full">
                <div className="w-full">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Full Name
                  </label>
                  <input
                    type="text"
                    value={user.fullName}
                    readOnly
                    disabled
                    className="w-full px-3 py-2 border rounded-lg text-sm focus:outline-none focus:ring-2"
                  />
                </div>
                <div className="w-full">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Email Address
                  </label>
                  <input
                    type="email"
                    value={user.primaryEmailAddress.emailAddress}
                    readOnly
                    disabled
                    className="w-full px-3 py-2 border rounded-lg text-sm focus:outline-none focus:ring-2"
                  />
                </div>
              </div>
              <PaymentMethodSelection
                paymentMethod={paymentMethod}
                setPaymentMethod={setPaymentMethod}
                errors={errors}
                setErrors={setErrors}
              />
            </div>
          </div>

          <div className="bg-white rounded-lg p-6 md:p-8 border border-slate-200">
            <h2 className="text-2xl font-bold text-gray-800 mb-6">
              Order Summary
            </h2>
            <div className="border border-slate-200 rounded-lg p-6 mb-6">
              <h4 className="text-lg font-semibold text-gray-800 mb-4">
                {selectedPlan.title} Plan
              </h4>
              <div className="mb-4">
                <span className="text-3xl font-bold text-transparent bg-clip-text bg-gradient-to-tl from-blue-600 to-violet-600">
                  {selectedPlan.price}
                </span>
                <p className="mt-2 text-sm text-gray-500">
                  {selectedPlan.description}
                </p>
              </div>
              <ul className="space-y-2 text-sm">
                {selectedPlan.features.map((feature, i) => (
                  <li key={i} className="flex items-center gap-x-2 text-gray-700">
                    <svg className="shrink-0 w-4 h-4 text-blue-600" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                      <polyline points="20 6 9 17 4 12" />
                    </svg>
                    {feature}
                  </li>
                ))}
              </ul>
            </div>

            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Upload Payment Proof
              </label>
              <div className="relative w-full border-2 border-dashed border-gray-300 rounded-lg p-6 text-center hover:border-blue-500 transition-colors">
                <input
                  type="file"
                  accept="image/*"
                  className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                  onChange={(e) => handlePaymentProofUpload(e.target.files[0])}
                />
                {paymentProof ? (
                  <img
                    src={URL.createObjectURL(paymentProof)}
                    alt="Payment Proof"
                    className="mx-auto max-h-48 rounded-lg object-contain"
                  />
                ) : (
                  <div className="flex flex-col items-center justify-center space-y-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="mx-auto h-10 w-10 text-gray-400"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                      />
                    </svg>
                    <p className="text-sm text-gray-600">
                      Drag and drop or click to upload transaction slip
                    </p>
                    <span className="text-xs text-gray-500">
                      PNG, JPG, JPEG up to 5MB
                    </span>
                  </div>
                )}
              </div>
              {errors.paymentProof && (
                <p className="text-red-500 text-sm mt-2">{errors.paymentProof}</p>
              )}
            </div>

            <h1 className="mb-2 text-xs text-gray-500">
              Complete manual payment and upload the transaction slip here, then click "Complete Checkout"!
            </h1>
            <button
              type="submit"
              className="w-full py-3 bg-gradient-to-r from-blue-600 to-violet-600 text-white font-semibold rounded-lg 
              hover:from-blue-700 hover:to-violet-700 transition-all duration-300 
              disabled:opacity-50 disabled:cursor-not-allowed"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Processing..." : "Complete Checkout"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
