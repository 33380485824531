import React from 'react';
import logo from '../../assets/logo2.png';

const Footer = () => {
  return (
    <footer className="bg-[#071C40] text-gray-700 px-6 py-12">
      <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Left Side - Social Media Cards */}
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          {/* Instagram */}
          <div className="p-6 bg-blue-50 rounded-lg">
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center gap-2">
                <i className="fab fa-instagram text-lg text-gray-700"></i>
                <span className="text-gray-700 text-sm font-medium">INSTAGRAM</span>
              </div>
              <i className="fas fa-arrow-up-right text-sm text-gray-700"></i>
            </div>
            <p className="text-gray-600 text-xs leading-relaxed">Share visually appealing snippets of our latest web projects.</p>
          </div>

          {/* Twitter */}
          <div className="p-6 bg-blue-50 rounded-lg">
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center gap-2">
                <i className="fab fa-twitter text-lg text-gray-700"></i>
                <span className="text-gray-700 text-sm font-medium">TWITTER</span>
              </div>
              <i className="fas fa-arrow-up-right text-sm text-gray-700"></i>
            </div>
            <p className="text-gray-600 text-xs leading-relaxed">Tweet about interesting coding challenges you've overcome.</p>
          </div>

          {/* Dribbble */}
          <div className="p-6 bg-blue-50 rounded-lg">
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center gap-2">
                <i className="fab fa-dribbble text-lg text-gray-700"></i>
                <span className="text-gray-700 text-sm font-medium">DRIBBBLE</span>
              </div>
              <i className="fas fa-arrow-up-right text-sm text-gray-700"></i>
            </div>
            <p className="text-gray-600 text-xs leading-relaxed">Showcase design elements of our web projects.</p>
          </div>

          {/* Behance */}
          <div className="p-6 bg-blue-50 rounded-lg">
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center gap-2">
                <i className="fab fa-behance text-lg text-gray-700"></i>
                <span className="text-gray-700 text-sm font-medium">BEHANCE</span>
              </div>
              <i className="fas fa-arrow-up-right text-sm text-gray-700"></i>
            </div>
            <p className="text-gray-600 text-xs leading-relaxed">Create detailed presentations for our projects.</p>
          </div>
        </div>

        {/* Right Side - Navigation, Newsletter, and Copyright */}
        <div className="space-y-4">
          {/* Navigation Links Card */}
          <div className="bg-blue-50 rounded-lg p-6">
            <div className="grid grid-cols-3 gap-4">
              {/* Logo and Description */}
              <div className="col-span-2">
                <div className="mb-3">
                  <img src={logo} alt="logo" className="w-64" />
                </div>
                <p className="text-gray-600 text-sm leading-relaxed mb-4">
                  Building digital experiences that inspire and innovate. We transform ideas into exceptional Saudi Businesses.
                </p>
              </div>

              {/* Blogs */}
              <div>
                <h3 className="text-gray-700 mb-4">Blogs</h3>
                <ul className="space-y-3 text-sm">
                  <li><a href="#" className="text-gray-600 hover:text-gray-900">Business</a></li>
                  <li>
                    <div className="flex items-center gap-2">
                      <span className="text-gray-600">Design</span>
                      <span className="text-gray-600 text-xs bg-blue-100 px-2 py-0.5 rounded">Soon</span>
                    </div>
                  </li>
                  <li>
                    <div className="flex items-center gap-2">
                      <span className="text-gray-600">Development</span>
                      <span className="text-gray-600 text-xs bg-blue-100 px-2 py-0.5 rounded">Soon</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* Newsletter Card */}
          <div className="bg-blue-50 rounded-lg p-6">
            <p className="text-gray-600 text-xs mb-2">NEWSLETTER</p>
            <div className="flex items-center justify-between">
              <span className="text-gray-700">SUBSCRIBE TO OUR NEWSLETTER</span>
              <div className="flex items-center gap-4">
                <input
                  type="email"
                  placeholder="Enter your email"
                  className="bg-transparent text-gray-700 text-sm focus:outline-none placeholder-gray-500"
                />
                <i className="fas fa-arrow-up-right text-sm text-gray-700"></i>
              </div>
            </div>
          </div>

          {/* Copyright Card */}
          <div className="bg-blue-50 rounded-lg p-6">
            <div className="flex justify-between items-center">
              <p className="text-gray-600 text-xs">© 2024 NextGen. All rights reserved.</p>
              <div className="flex gap-6 text-xs">
                <a href="#" className="text-gray-600 hover:text-gray-900">Terms & Conditions</a>
                <a href="#" className="text-gray-600 hover:text-gray-900">Privacy Policy</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;