import React from 'react';

const OrderStatusPage = () => {
    return (
        <>
            <div>
                <h1 className="text-3xl text-gray-800 font-semibold">Payment Verification Status</h1>
                <p className="text-gray-500">Manage your subscription and billing details</p>
            </div>
            <div className="container mx-auto px-4 py-12 max-w-7xl">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                    <div className="bg-white rounded-lg p-6 md:p-8 border border-slate-200">
                        <h2 className="text-2xl font-bold text-gray-800 mb-6">
                            Order Details
                        </h2>
                        <div className="space-y-4">
                            <div className="bg-gray-100 rounded-lg p-4">
                                <div className="flex justify-between items-center mb-2">
                                    <span className="text-sm text-gray-600">Order Number</span>
                                    <span className="text-sm font-semibold text-gray-800">#12345</span>
                                </div>
                                <div className="flex justify-between items-center mb-2">
                                    <span className="text-sm text-gray-600">Order Date</span>
                                    <span className="text-sm font-semibold text-gray-800">January 15, 2024</span>
                                </div>
                                <div className="flex justify-between items-center">
                                    <span className="text-sm text-gray-600">Payment Status</span>
                                    <span className="text-sm font-semibold text-green-600">Paid</span>
                                </div>
                            </div>
                            <div className="border-t border-gray-200 pt-4">
                                <h3 className="text-lg font-semibold text-gray-800 mb-3">Shipping Information</h3>
                                <p className="text-sm text-gray-600">
                                    John Doe<br />
                                    123 Main Street<br />
                                    Anytown, State 12345<br />
                                    United States
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="bg-white rounded-lg p-6 md:p-08 border border-slate-200">
                        <h2 className="text-2xl font-bold text-gray-800 mb-6">
                            Order Summary
                        </h2>
                        <div className="border border-slate-200 rounded-lg p-6 mb-6">
                            <h4 className="text-lg font-semibold text-gray-800 mb-4">
                                Pro Plan
                            </h4>
                            <div className="mb-4">
                                <span className="text-3xl font-bold text-transparent bg-clip-text bg-gradient-to-tl from-blue-600 to-violet-600">
                                    $99.99/month
                                </span>
                                <p className="mt-2 text-sm text-gray-500">
                                    Comprehensive professional plan
                                </p>
                            </div>
                            <ul className="space-y-2 text-sm">
                                {['Unlimited Projects', 'Priority Support', 'Advanced Analytics'].map((feature, i) => (
                                    <li key={i} className="flex items-center gap-x-2 text-gray-700">
                                        <svg className="shrink-0 w-4 h-4 text-blue-600" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                                            <polyline points="20 6 9 17 4 12" />
                                        </svg>
                                        {feature}
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="bg-gray-100 rounded-lg p-4">
                            <div className="flex justify-between items-center mb-2">
                                <span className="text-sm text-gray-600">Subtotal</span>
                                <span className="text-sm font-semibold text-gray-800">$99.99</span>
                            </div>
                            <div className="flex justify-between items-center mb-2">
                                <span className="text-sm text-gray-600">Tax</span>
                                <span className="text-sm font-semibold text-gray-800">$9.99</span>
                            </div>
                            <div className="flex justify-between items-center border-t border-gray-200 pt-2">
                                <span className="text-sm font-bold text-gray-800">Total</span>
                                <span className="text-lg font-bold text-transparent bg-clip-text bg-gradient-to-tl from-blue-600 to-violet-600">
                                    $109.98
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default OrderStatusPage;