import React from 'react'
import { Link } from 'react-router-dom'

function SubscriptionTime() {
  return (
    <div className="flex flex-col items-center justify-center min-h-[400px] p-6 bg-white border border-slate-100 rounded">
      <div className="text-center max-w-md">
        <div className="mb-6 flex justify-center">
          <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" className="text-gray-500 opacity-70">
            <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
            <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
          </svg>
        </div>
        
        <h2 className="text-xl font-semibold text-gray-600 mb-2">Access Restricted</h2>
        
        <h1 className="text-3xl font-bold text-gray-900 mb-4">
          Unlock Premium Features
        </h1>
        
        <p className="text-gray-600 mb-6 leading-relaxed">
          This feature is exclusively available for Gold and Platinum subscribers. 
          Upgrade your plan to access advanced functionalities and enhance your experience.
        </p>
        
        <Link 
          to='/pricing' 
          className="flex items-center justify-center gap-2 w-full py-3 px-6 
                     bg-blue-600 hover:bg-blue-700 text-white 
                     rounded-full transition-colors duration-300 
                     font-semibold shadow-md hover:shadow-lg"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <rect x="1" y="4" width="22" height="16" rx="2" ry="2"></rect>
            <line x1="1" y1="10" x2="23" y2="10"></line>
          </svg>
          Upgrade Subscription
        </Link>
      </div>
    </div>
  )
}

export default SubscriptionTime